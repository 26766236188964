const mapMonth = new Map();
mapMonth.set('January', "ม.ค.");
mapMonth.set("February", "ก.พ.");
mapMonth.set("March", "มี.ค.");
mapMonth.set("April", "เม.ย.");
mapMonth.set("May", "พ.ค.");
mapMonth.set("June", "มิ.ย.");
mapMonth.set("July", "ก.ค.");
mapMonth.set("August", "ส.ค.");
mapMonth.set("September", "ก.ย.");
mapMonth.set("October", "ต.ค.");
mapMonth.set("November", "พ.ย.");
mapMonth.set("December", "ธ.ค.");

// December 2023 => ธ.ค. 2023
export function convertToThaiMonth(data) {
    for (let i = 0; i < data.length; i++) {
        const split = data[i].split(" ");
        let monthTH = mapMonth.get(split[0])
        let result = monthTH + " " + split[1]
        data[i] = result
    }
}

// January => ม.ค.
export function convertToThaiMonthNoSplit(data) {
    for (let i = 0; i < data.length; i++) {
        let monthTH = mapMonth.get(data[i])
        data[i] = monthTH
    }
}

// 2023-12-01 => 1 ธ.ค. 23
export function convertToThaiFormat(data) {
    for (let i = 0; i < data.length; i++) {
        const date = data[i].replace(/^0+/, '');
        const splitDate = date.split("-");
        const day = splitDate[2].replace(/^0+/, '');
        const month = mapMonth.get(new Date(date).toLocaleString('default', { month: 'long' }));
        const year = splitDate[0].slice(2);

        const convertedDate = `${day} ${month} ${year}`;
        data[i] = convertedDate
    }
}


export function convertDateFullEngToThaiFormat(data) {
    for (let i = 0; i < data.length; i++) {
        const date = data[i];
        const splitDate = date.split(" ");
        const day = splitDate[0].replace(/^0+/, '');
        const month = mapMonth.get(new Date(date).toLocaleString('default', { month: 'long' }));
        const year = splitDate[2].slice(2);

        const convertedDate = `${day} ${month} ${year}`;
        data[i] = convertedDate
    }
}


// 2023-12-01 => 1 ธ.ค.
export function convertToThaiFormatWithOutYear(data) {
    for (let i = 0; i < data.length; i++) {
        const date = data[i].replace(/^0+/, '');
        const splitDate = date.split("-");
        const day = splitDate[2].replace(/^0+/, '');
        const month = mapMonth.get(new Date(date).toLocaleString('default', { month: 'long' }));

        const convertedDate = `${day} ${month}`;
        data[i] = convertedDate
    }
}