import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import Hospitals from "./Hospitals";
import Summary from "./Summary";
highchartsMap(Highcharts); // Initialize the map module

function Country() {
  const [data, setA] = useState();

  useEffect(() => {
    const dataTH = [
      ["th-bm", "3852"],
      ["th-kk", "279"],
    ];

    (async () => {
      const topology = await fetch(
        "https://code.highcharts.com/mapdata/countries/th/th-all.topo.json"
      ).then((response) => response.json());
      const mapOptions = {
        chart: {
          map: topology,
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "{point.name} : {point.value}",
        },
        series: [
          {
            data: dataTH,
            name: "",
            borderColor: "#FFFFFF",
            nullColor: "#BBBBBB",
            showInLegend: false,
            color: "#4FD1C5",
            cursor: "pointer",
            // dataLabels: {
            //     enabled: true,
            //     format: '{point.name}'
            // },
            point: {
              events: {
                click: function () {
                  // implement API
                  console.log(this);
                  let bk = `
                                    ***ข้อมูลที่แสดงต่อไปนี้ เป็นข้อมูล demo***
                                    รพ. ใน ${
                                      this.name
                                    } (${this.properties.hasc.toLowerCase()})  \n
                                    1.ศูนย์การแพทย์กาญจนาภิเษก (100) \n
                                    2.โรงพยาบาลศิริราช (3052) \n
                                    3.โรงพยาบาลศิริราชปิยมหาราชการุณย์ (700) \n `;

                  let kk = `
                                    ***ข้อมูลที่แสดงต่อไปนี้ เป็นข้อมูล demo***
                                    รพ. ใน ${
                                      this.name
                                    } (${this.properties.hasc.toLowerCase()})  \n
                                    1.รพศรีนครินทร์ ขอนแก่น (279) \n`;

                  let hcKey = this.properties.hasc
                    .toLowerCase()
                    .replace(".", "-");
                  console.log(hcKey, "hcKey");
                  if (hcKey === "th-bm") {
                    console.log("1");
                    alert(bk);
                  } else if (hcKey === "th-kk") {
                    console.log("2");
                    alert(kk);
                  }
                },
              },
            },
          },
        ],
      };
      setA(mapOptions);
    })();
  }, []);

  return (
    <div className="flex-[1] h-full xl:h-[600px] items-center grid grid-cols-1 xl:flex justify-center bg-white rounded-xl p-6">
      <div className="w-full xl:w-[380px] h-full">
        <Summary />
      </div>
      <div className="flex-1 h-full items-center justify-center xl:ml-4 mt-8 xl:mt-0">
        <div className="flex w-full h-[400px] xl:h-4/6 ">
          <HighchartsReact
            constructorType={"mapChart"}
            highcharts={Highcharts}
            options={data}
          />
        </div>
        <div className="flex w-full xl:h-2/6">
          <Hospitals />
        </div>
      </div>
    </div>
  );
}

export default Country;
