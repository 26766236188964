import { useState, useEffect } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  LinearScale,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { convertToThaiFormatWithOutYear } from "../../utils/utils";
import Smile from "../../assets/svg/smile.svg";

ChartJS.register(
  LinearScale,
  LineElement,
);

function PatientLogs() {
  const [datasets, setDataSets] = useState();
  const [labels, setLabel] = useState();
  const [value, setValue] = useState("all");
  const [total, setTotal] = useState(0);
  // let currentDate = new Date();
  // let currentYear = Number(currentDate.getFullYear());
  let currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [hospital, setHospital] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    (async () => {
      const r = await axios({
        method: "get",
        url: `https://analytics-api.healthtag.io/patient-logs-total?year=${year}&filter=${value}&hospitalAddress=${hospital}`,
        withCredentials: false,
      });
      if (r.status === 200) {
        setDataSets(r.data.datasets);
        convertToThaiFormatWithOutYear(r.data.labels)
        setLabel(r.data.labels);
        let sum = 0;
        for (let i=0; i < r.data.datasets.length; i++) {
          sum += r.data.datasets[i]
        }
        setTotal(sum)
      }
    })();
  }, [value, year, hospital]);

  const handleChangeYear = (event) => {
    setYear(Number(event.target.value));
  };

  const handleChangeHospital = (event) => {
    setHospital(event.target.value);
  };

  return (
    <div className='flex flex-col h-full items-center justify-center bg-white rounded-xl p-6 mt-8'>
      <div>
        <p className="text-[#2D3748] text-[18px] font-bold">จำนวนการใช้ระบบ (#Transaction)</p>
      </div>
      <label className="text-sm flex w-full items-center justify-center mt-4">
        <select value={hospital} onChange={handleChangeHospital} className="border-[#DFDFDF] border-2 rounded-xl p-2 text-[#39434F]">
            <option value="">ทุกโรงพยาบาล</option>
            <option value="0xb793082BA90fcc43b5bBFeEbf99eD98718fE9d24">ศูนย์การแพทย์กาญจนาภิเษก</option>
            <option value="0x9571680BaE0C7B2b38D124Eb4f267a9748571596">โรงพยาบาลศิริราช</option>
            <option value="0x0C4693AAF4143B6251f08C3C5f20259b20F44458">โรงพยาบาลศิริราชปิยมหาราชการุณย์</option>
        </select>
        <select value={year} onChange={handleChangeYear} className="border-[#DFDFDF] border-2 rounded-xl p-2 text-[#39434F] ml-2">
          <option value={year + 1}>{year + 1}</option>
          <option value={year}>{year}</option>
          <option value={year - 1}>{year - 1}</option>
        </select>
        <select value={value} onChange={handleChange} className="border-[#DFDFDF] border-2 rounded-xl p-2 text-[#39434F] ml-2">
          <option value="all">All</option>
          <option value="distinct">Distinct</option>
        </select>
    </label>
    <div className="flex flex-col xl:flex-row w-full items-center justify-center flex-wrap">
      <Line
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: "top",
            },
            title: {
              display: false,
              text: `จำนวนการใช้ระบบ (#Transaction) ${total} ครั้งในปี ${year}`,
              color: "#949CB0",
            },
          },
          scales: {
            x: {
              border: {
                display: true
              },
              grid: {
                display: false,
              }
            },
            y: {
              position: 'right',

              border: {
                display: false
              },
              grid: {
                display: true,
              },
            }
          }
        }}
        data={{
          labels: labels,
          datasets: [
            {
              label: "การใช้งานระบบ",
              data: datasets,
              backgroundColor: "#FFFFFF",
              borderColor: "#4FD1C5",
            },
          ],
        }}
        className="max-h-[420px] max-w-[1500px] mt-4"
      />
      <div className="flex max-w-[250px] min-w-[250px] w-full h-[93px] border-[#394CFF] border-[1px] rounded-xl justify-center items-center ml-8 drop-shadow-xl bg-white mt-4 xl:mt-0">
        <img src={Smile} alt="" className="w-[38.5px]" />
        <div className="ml-4">
          <p className="text-[#3A4DE9] text-[16px] font-bold">{total} การใช้งานระบบ</p>
          <p className="text-[16px]">ปี {year}</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default PatientLogs;
