import React from 'react'
import DefaultSvg from '../../assets/svg/default.svg';
import Noti from '../../assets/svg/noti.svg';
import Setting from '../../assets/svg/sharp.svg';

function UserDetail() {
  return (
    <div className="flex flex-col h-full text-xs xl:text-sm justify-between items-end">
        <div className='flex flex-row items-center justify-center'>
            <img src={DefaultSvg} alt="" className='w-[24px]' />
            <p className='text-[#A0AEC0] text-[12px] xl:text-[15px] font-bold ml-2'>Sign In</p>
            <img src={Setting} alt="" className='w-[24px] ml-4 xl:ml-8' />
            <img src={Noti} alt="" className='w-[24px] ml-4' />
        </div>
        <p className='text-[#A0AEC0] text-[10px] xl:text-[13px]'>SUPERDASH BOARD by Hos</p>
    </div>
  )
}

export default UserDetail