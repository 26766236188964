import React from 'react'
import PatientLogs from './PatientLogs'
import PatientSummary from './patient-summary/PatientSummary'
import PoolPHR from '../PoolPHR'

function Chart() {
  return (
      <div className="flex flex-col bg-[#F8F9FA] dialog-layout w-full min-h-screen xl:p-8">
          <PatientSummary />
          <PatientLogs />
          <PoolPHR />
      </div>
  )
}

export default Chart