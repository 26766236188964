import { useState, useEffect } from "react";
import Graph from "./Graph";
import GraphAccumulate from "./GraphAccumulate";

function PatientGraph() {
  const classClicked = 'bg-[#303030] text-[#FFFFFF]';
  const [isDayClicked, setIsDayClicked] = useState(true);
  const [isMonthClicked, setIsMonthClicked] = useState(false);
  const [isYearClicked, setIsYearClicked] = useState(false);

  const handleDayClick = () => {
    setIsDayClicked(true);
    setIsMonthClicked(false);
    setIsYearClicked(false);
  };

  const handleMonthClick = () => {
    setIsDayClicked(false);
    setIsMonthClicked(true);
    setIsYearClicked(false);
  };

  const handleYearClick = () => {
    setIsDayClicked(false);
    setIsMonthClicked(false);
    setIsYearClicked(true);
  };

  let currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [hospital, setHospital] = useState("");
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const m = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const handleChangeYear = (event) => {
    setYear(Number(event.target.value));
  };

  const handleChangeMonth = (event) => {
    setMonth(Number(event.target.value));
  };

  const handleChangeHospital = (event) => {
    setHospital(event.target.value);
  };

  return (
    <div className='flex flex-col flex-[1] h-[600px] xl:h-[600px] mt-8 xl:mt-0 bg-white rounded-xl xl:ml-4 p-6'>
        {/* Top menu */}
        <div className='flex items-center justify-between w-full h-[50px]'>
            <p className='text-[18px] text-[#2D3748]'>กราฟแสดงจำนวนการใช้งาน</p>
            <div className='text-sm'>
                <button className={`w-[48px] xl:w-[96px] h-[34px] rounded-md ${isDayClicked ? classClicked : 'bg-[#F5F5F5] text-[#949CB0]'}`} onClick={handleDayClick}>รายวัน</button>
                <button className={`ml-2 w-[60px] xl:w-[96px] h-[34px] rounded-md ${isMonthClicked ? classClicked : 'bg-[#F5F5F5] text-[#949CB0]'}`} onClick={handleMonthClick}>รายเดือน</button>
                <button className={`ml-2 w-[54px] xl:w-[96px] h-[34px] rounded-md ${isYearClicked ? classClicked : 'bg-[#F5F5F5] text-[#949CB0] '}`} onClick={handleYearClick}>รายปี</button>
            </div>
        </div>

        {/* Select */}
        <div className='flex items-center w-full h-[50px] mt-2'>
          <label className="text-sm">
            <select className="border-[#DFDFDF] border-2 rounded-xl p-2 text-[#39434F] w-[160px] xl:w-auto" value={hospital} onChange={handleChangeHospital}>
              <option value="">ทุกโรงพยาบาล</option>
              <option value="0xb793082BA90fcc43b5bBFeEbf99eD98718fE9d24">ศูนย์การแพทย์กาญจนาภิเษก</option>
              <option value="0x9571680BaE0C7B2b38D124Eb4f267a9748571596">โรงพยาบาลศิริราช</option>
              <option value="0x0C4693AAF4143B6251f08C3C5f20259b20F44458">โรงพยาบาลศิริราชปิยมหาราชการุณย์</option>
            </select>
            {isYearClicked ? null :
              <select className="border-[#DFDFDF] border-2 rounded-xl p-2 text-[#39434F] ml-2" value={year} onChange={handleChangeYear}>
                <option value={year + 1}>{year + 1}</option>
                <option value={year}>{year}</option>
                <option value={year - 1}>{year - 1}</option>
              </select>
            }
            {isMonthClicked || isYearClicked ? null : 
              <select className="border-[#DFDFDF] border-2 rounded-xl p-2 text-[#39434F] ml-2" value={month} onChange={handleChangeMonth}>
                {m.map((item, index) => {
                  return (
                    <option key={index + 1} value={index + 1}>
                      {item}
                    </option>
                  );
                })}
              </select>
            }
          </label>
        </div>

        {/* graph */}
        {isDayClicked ? (
          <>
            <Graph hospital={hospital} month={month} year={year} mode="Day"/>
            <GraphAccumulate hospital={hospital} month={month} year={year} mode="Day"/>
          </>
        ) : null}
        {isMonthClicked ? (
          <>
            <Graph hospital={hospital} month={month} year={year} mode="Month"/>
            <GraphAccumulate hospital={hospital} month={month} year={year} mode="Month"/>
          </>
        ) : null}
        {isYearClicked ? (
          <>
            <Graph hospital={hospital} month={month} year={year} mode="Year"/>
            <GraphAccumulate hospital={hospital} month={month} year={year} mode="Year"/>
          </>
        ) : null}
        
    </div>
  )
}

export default PatientGraph