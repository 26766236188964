import Chart from "./components/charts/Chart";
import Header from "./components/header/Header";


function App() {
  return (
    <>
      <div class="relative">
        <Header />
      <div className="mt-[88px]">
        <Chart />
      </div>
    </div>
    </>
  );
}

export default App;
