import React from 'react'
import Country from './Country'
import PatientGraph from './PatientGraph'

function PatientSummary() {
  return (
    <div className="w-full h-auto items-center grid grid-cols-1 xl:flex justify-between mt-4 flex-wrap">
        <Country />
        <PatientGraph />
    </div>
  )
}

export default PatientSummary