import React from 'react'

function PageDetail() {
  return (
    <div className="flex flex-col h-full text-xs xl:text-sm justify-center">
        <p>
            <span className='text-[#A0AEC0] mr-2 xl:mr-4'>Pages</span>
            <span className='text-[#2D3748]'>/  Dashboard</span>
        </p>
        <p className='text-[#2D3748] font-bold'>HEALTHTAG NETWORK DASHBOARD</p>
        <p className='text-[#2D3748] '>โรงพยาบาลศิริราช, 2024</p>
    </div>
  )
}

export default PageDetail