import React from 'react'
import PageDetail from './PageDetail'
import UserDetail from './UserDetail'

function Header() {
  return (
    <div className="flex bg-[#F8F9FA] flex-row w-full h-[88px] px-4 py-[15px] xl:px-8 fixed top-0 left-0 right-0 drop-shadow-lg items-center justify-between z-10">
        <PageDetail />
        <UserDetail />
    </div>
  )
}

export default Header